import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const { handleCall } = props
  const { openNotification } = hooks.useNotification()
  const [handleResetPasswordAction] = hooks.useModelFunction('stage', 'handleResetPasswordAction')
  const [gsm] = hooks.useFieldValues(['auth.gsm'])

  const resetPassword = useCallback(Callbacks.resetPasswordHandler(openNotification, handleResetPasswordAction), [openNotification, handleResetPasswordAction])
  const onCallDriver = useCallback(Callbacks.onCallDriverHandler(handleCall, gsm, openNotification), [handleCall, gsm, openNotification])

  const mergedProps = {
    resetPassword,
    onCallDriver
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
