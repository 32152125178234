import React from 'react'
import { I18n } from '@front/volcanion'

import { ConfirmationDialog } from '@front/squirtle'

const Dialogs = ({ resetPassword, onCallDriver }) => (<>
  <ConfirmationDialog name={'confirm_reset_password'} onConfirm={resetPassword} />
  <ConfirmationDialog
    name={'confirm_call_driver'}
    onConfirm={onCallDriver}
    yesLabel={I18n.t('action.confirm')}
    noLabel={I18n.t('action.cancel')}
  />
</>
)

export default React.memo(Dialogs)
