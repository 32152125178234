import { I18n } from '@front/volcanion'
import Aux from './Aux'
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        contact: {
          gsm: _.get(record, 'contact.gsm'),
          email: _.get(record, 'contact.email'),
          first_name: _.get(record, 'contact.first_name'),
          last_name: _.get(record, 'contact.last_name')
        },
        status: Aux.loadStatus(record),
        companycontract: _.get(record, 'companycontract'),
        deferred_billing: _.get(record, 'deferred_billing'),
        availableSince: _.get(record, 'availableSince'),
        availableUntil: _.get(record, 'availableUntil'),
        self_booking: _.get(record, 'self_booking'),
        booking_level: _.get(record, 'booking_level'),
        guest_booking: _.get(record, 'guest_booking'),
        administration_level: _.get(record, 'administration_level'),
        update: _.get(record, 'update'),
        companyuser_services: _.map(_.get(record, 'companyuser_services'), elem => ({
          companyuserservice_id: _.get(elem, 'companyuserservice_id'),
          companycontract: _.get(elem, 'companyservice.contract'),
          companyservice: _.get(elem, 'companyservice.companyservice_id')
        }))
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues(record) {
      return {
        self_booking: true,
        guest_booking: false,
        status: 'active',
      }
    }
  }

  static formToRecordHandler(company, application) {
    return function formToRecord(values, extra, meta, state) {
      return _.merge(
        !state?.user_id ? {
          user: {
            auth: {
              gsm: _.get(values, 'contact.gsm'),
              application
            },
            customerinfo: {},
            info: {
              first_name: _.get(values, 'contact.first_name'),
              last_name: _.get(values, 'contact.last_name'),
            },
          }
        } : {},
        {
          contact: {
            gsm: _.get(values, 'contact.gsm'),
            email: _.get(values, 'contact.email'),
            first_name: _.get(values, 'contact.first_name'),
            last_name: _.get(values, 'contact.last_name')
          },
          companycontract: _.get(values, 'companycontract'),
          deferred_billing: _.get(values, 'deferred_billing'),
          availableSince: Aux.exportDate(_.get(values, 'availableSince')),
          availableUntil: Aux.exportDate(_.get(values, 'availableUntil')),
          self_booking: _.get(values, 'self_booking'),
          booking_level: _.get(values, 'booking_level'),
          guest_booking: _.get(values, 'guest_booking'),
          administration_level: _.get(values, 'administration_level'),
          update: _.get(values, 'update'),
          companyuser_services: _.has(values, 'companyuser_services')
            ? _.map(_.compact(_.get(values, 'companyuser_services')), elem => ({
              company,
              companyservice: _.get(elem, 'companyservice')
            }))
            : undefined,
          company,
          ...Aux.exportStatus(_.get(values, 'status'))
        })
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'user.customerinfo.client_number'),
        company: _.get(record, 'company'),
        user_id: _.get(record, 'user.user_id')
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(err, values, extra, meta) {
      if (_.includes(['RECORD_EXISTS'], err.getCode())) {
        return [I18n.t('companyuser.record_exists'), { variant: 'error' }]
      }
    }
  }
}

export default Callbacks
